<template>
  <router-link :to="routerPath" :tag="tag">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "AopLink",
  props: {
    routerPath: {
      type: String,
      default: "/"
    },

    tag: {
      type: String,
      default: "a"
    }
  }
};
</script>

<style scoped lang="scss"></style>
