import { render, staticRenderFns } from "./LayoutHeaderNavigationItem.vue?vue&type=template&id=4dcf0902&scoped=true&"
import script from "./LayoutHeaderNavigationItem.vue?vue&type=script&lang=js&"
export * from "./LayoutHeaderNavigationItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dcf0902",
  null
  
)

export default component.exports