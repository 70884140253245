<template>
  <li class="sidenav-navigation__item">
    <aop-link :routerPath="{ name: link.name }">{{ link.meta.name }}</aop-link>
  </li>
</template>

<script>
import AopLink from "@/modules/UiKit/components/links/AopLink";
export default {
  name: "LayoutSidenavNavigationItem",
  components: { AopLink },
  props: {
    link: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.sidenav-navigation__item {
  position: relative;
  font-size: 14px;
  line-height: 20px;

  /deep/ {
    a {
      color: $text-default;
      padding-bottom: 8px;

      &:hover {
        color: $text-subdued;
      }
    }

    .router-link-active {
      color: $action-primary;
    }
  }
}
</style>
