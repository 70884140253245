<template>
  <li class="header-navigation__item">
    <aop-link :routerPath="{ name: link.meta.title }">{{ link.meta.name }}</aop-link>
  </li>
</template>

<script>
import AopLink from "@/modules/UiKit/components/links/AopLink";

export default {
  name: "LayoutHeaderNavigationItem",
  components: { AopLink },
  props: {
    link: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss"></style>
