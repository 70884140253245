<template>
  <aside class="layout-sidenav">
    <layout-sidenav-navigation />
  </aside>
</template>

<script>
import LayoutSidenavNavigation from "@/modules/UiKit/layouts/LayoutSidenav/LayoutSidenavNavigation";

export default {
  name: "LayoutSidenav",
  components: { LayoutSidenavNavigation }
};
</script>

<style scoped lang="scss">
.layout-sidenav {
  width: 150px;
  flex-shrink: 0;
  padding-right: 20px;
}
</style>
