<template>
  <nav class="sidenav-navigation">
    <ul class="sidenav-navigation__list">
      <layout-sidenav-navigation-item
        v-for="link in componentsRoutes"
        :link="link"
        :key="link.meta.name"
      />
    </ul>
  </nav>
</template>

<script>
import LayoutSidenavNavigationItem from "@/modules/UiKit/layouts/LayoutSidenav/LayoutSidenavNavigationItem";

export default {
  name: "LayoutSidenavNavigation",
  components: { LayoutSidenavNavigationItem },
  data() {
    return {
      componentsRoutes: []
    };
  },
  mounted() {
    this.setSidenavRoutes();
  },
  methods: {
    setSidenavRoutes() {
      let mainPath = this.$router.options.routes.find((item) => item.path === "/ui");
      if (
        mainPath.children
          .find((item) => item.path === this.$route.path.split("/")[2])
          .hasOwnProperty("children")
      ) {
        this.componentsRoutes = mainPath.children.find(
          (item) => item.path === this.$route.path.split("/")[2]
        ).children;
      } else {
        this.componentsRoutes = [];
      }
    }
  },
  computed: {},
  watch: {
    $route() {
      this.setSidenavRoutes();
    }
  }
};
</script>

<style scoped lang="scss">
.sidenav-navigation {
  &__list {
    list-style: none;
    padding: 0;
  }
  &__item {
    margin-bottom: 10px;
  }
}
</style>
