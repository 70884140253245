<template>
  <nav class="header-navigation">
    <ul class="header-navigation__list">
      <layout-header-navigation-item
        v-for="link in navigationLinks[0].children"
        :key="link.title"
        :link="link"
      />
    </ul>
  </nav>
</template>

<script>
import { UiKitRoutes } from "@/modules/UiKit/routes/ui-kit-routes";
import LayoutHeaderNavigationItem from "@/modules/UiKit/layouts/LayoutHeader/LayoutHeaderNavigationItem";

export default {
  name: "LayoutHeaderNavigation",
  components: { LayoutHeaderNavigationItem },
  data() {
    return {
      navigationLinks: UiKitRoutes
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.header-navigation__list {
  display: flex;
  margin-bottom: 0;

  list-style: none;

  .header-navigation__item {
    position: relative;
    font-size: 14px;
    line-height: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }

    /deep/ {
      a {
        color: $text-default;
        padding-bottom: 8px;

        &:hover {
          color: $text-subdued;
        }
      }

      .router-link-active:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background: $action-primary;
      }
    }
  }
}
</style>
