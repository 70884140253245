<template>
  <div class="ui-kit-layout">
    <Header />
    <div class="bottom-container">
      <Sidebar />
      <main class="main-content">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import Header from "@/modules/UiKit/layouts/LayoutHeader/LayoutHeader";
import Sidebar from "@/modules/UiKit/layouts/LayoutSidenav/LayoutSidenav";

export default {
  name: "UIKitLayout",
  components: { Sidebar, Header }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/base/color/colors.scss";

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.ui-kit-layout {
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $bg-surface-gradient;

  .bottom-container {
    display: flex;
  }

  .main-content {
    width: 100%;
    height: 100vh;
    max-height: calc(100vh - 124px);
    overflow-y: scroll;
    padding: 24px;
    border-radius: 16px;
    background: $bg-surface-primary;
    box-shadow: 0 0 2px rgba(167, 169, 192, 0.3), 0 3px 10px rgba(167, 169, 192, 0.2);

    .page-header {
      margin-bottom: 30px;
    }
  }
}
</style>
