<template>
  <header class="layout-header">
    <img src="@/assets/img/imgAuth/logoForm.svg" alt="logo" />
    <layout-header-navigation />
  </header>
</template>

<script>
import LayoutHeaderNavigation from "@/modules/UiKit/layouts/LayoutHeader/LayoutHeaderNavigation";

export default {
  name: "LayoutHeader",
  components: { LayoutHeaderNavigation }
};
</script>

<style scoped lang="scss">
.layout-header {
  display: flex;
  align-items: center;
  height: 100px;
}
</style>
